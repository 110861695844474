import React from "react";
import { Link } from "react-router-dom";
import "../static/main.css";
import { RxHamburgerMenu } from "react-icons/rx";
import { HiOutlineXMark } from "react-icons/hi2";
import { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { ArrowDown01Icon } from "hugeicons-react";

const GeneralNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="general-nav">
      <nav className="container padding-container">
        <Link to="/" className="logo link">
          <img src="/images/logo.png" alt="Logo" />
          <h1>
            Carnegie Tri-County <br /> Municipal Hospital
          </h1>
        </Link>
        <ul className={isOpen ? "navOpen" : ""}>
          <li>
            <Link to="/" className="link">
              Home
            </Link>
          </li>

          <li>
            <Link to="/about" className="link">
              About Us
            </Link>
          </li>
          <li className="patient-info">
            <Link to="/patient-info" className="link">
              Patient Info
            </Link>
            <ArrowDown01Icon
              id="down-arrow"
              size={24}
              color={"#000000"}
              variant={"stroke"}
            />
            <div className="patient-info-popup">
              <Link
                to="https://www.carnegiehospital.com/documents/2020-Financial-Assistance-Policy.CARNEGIE.tdc_.pdf"
                className="patient-info-popup-link"
              >
                FA Policy
              </Link>
              <Link
                className="patient-info-popup-link"
                to="https://www.carnegiehospital.com/documents/2020-APPLICATION.Carnegie.-Patient-Assistance-Application.pdf"
              >
                FA Application
              </Link>
              <Link
                className="patient-info-popup-link"
                to="https://apps.para-hcfs.com/PTT/FinalLinks/CarnegieTriCounty_V2.aspx"
              >
                Price Transparency
              </Link>
            </div>
          </li>
          <li id="services">
            <Link to="/services" className="link">
              Services
            </Link>
          </li>
          {/* <li>
            <Link to="/appointment" className="link">
              Appointment
            </Link>
          </li> */}
          <li>
            <Link to="/contact" className="link">
              Contact Us
            </Link>
          </li>
          <li>
            <Link
              to="https://www.securebillpay.net/carnegietricounty"
              className="link"
            >
              Make a Payment
            </Link>
          </li>
          <li>
            <Link
              to="https://recruiting.paylocity.com/recruiting/jobs/All/61a4644b-8505-4d28-bd52-31ce929f7f63/Cohesive-Staffing-Solutions?location=Carnegie%20Hospital&department=All%20Departments"
              className="link"
            >
              Employment
            </Link>
          </li>
          <li>
            <Link to="/clinic" className="clinic">
              Carnegie Medical Clinic
            </Link>
          </li>
        </ul>
        <div
          className={`hamburger-container ${isOpen ? "hidden" : "hamburger-container"
            }`}
          onClick={toggleMenu}
        >
          <RxHamburgerMenu className="hamburger" />
        </div>
        <div
          className={`close-hamburger-container ${!isOpen ? "hidden" : "hamburger-container"
            }`}
          onClick={toggleMenu}
        >
          <HiOutlineXMark className="close-hamburger" />
        </div>
      </nav>
    </header>
  );
};

export default GeneralNav;
